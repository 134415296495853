import styled from "@emotion/styled";
import { colors } from "styles";

const Svg = styled.svg`
  fill: #01314a;
  &:hover {
    fill: #0083c8;
  }
`;

export const BackIcon = ({ title }: { title?: string }) => {
  return (
    <Svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g transform="translate(2,2)">
        <path d="M16 0.999999C24.2843 0.999999 31 7.71573 31 16L33 16C33 6.61116 25.3888 -1 16 -1L16 0.999999ZM1 16C1 7.71573 7.71573 0.999998 16 0.999999L16 -1C6.61116 -1 -0.999998 6.61116 -0.999999 16L1 16ZM16 31C7.71573 31 1 24.2843 1 16L-0.999999 16C-0.999999 25.3888 6.61116 33 16 33L16 31ZM16 33C25.3888 33 33 25.3888 33 16L31 16C31 24.2843 24.2843 31 16 31L16 33Z" />
        <path d="M8.86192 16.4714L13.9999 21.6094L14.9426 20.6667L10.9426 16.6667L22.6666 16.6667C23.0353 16.6667 23.3333 16.368 23.3333 16C23.3333 15.632 23.0353 15.3334 22.6666 15.3334L10.9426 15.3334L14.9426 11.3334L13.9999 10.3907L8.86192 15.5287C8.60125 15.7894 8.60125 16.2107 8.86192 16.4714Z" />
      </g>
    </Svg>
  );
};
