import { atom, useAtom } from "jotai";
import { loadable } from "jotai/utils";
import { debug, fetchText } from "tools";
import { MarkdownItem } from "types";

/**
 * Atom value used to refresh the `entitlementsLoadble` whenever it is incremented
 */
const refreshAtom = atom(0);

interface MarkdownReductionItem {
  itemType: string;
  label: string;
  icon: string;
  rows: string[];
}

type MarkdownReduction = {
  currentLabel: string | null;
  items: { [key: string]: MarkdownReductionItem };
};

/**
 * Loadable atom which retrieves, processes, and returns the `entitlements` data (using
 * the markdown specified in `entitlementMarkdown`).
 *
 * This is used on the entitlements page.
 *
 * See: https://jotai.org/docs/utils/loadable
 */
export const entitlementsLoadble = loadable(
  atom(async (get) => {
    get(refreshAtom); // used to trigger a refresh/reload
    const [markdown, MarkdownIt] = await Promise.all([
      fetchText("/assets/entitlements.md"),
      import("markdown-it").then((mod) => mod.default),
    ]);
    const md = new MarkdownIt({ html: true, linkify: true });

    const reductionItems = markdown.split("\n").reduce<MarkdownReduction>(
      ({ currentLabel, items }, v: string) => {
        if (v.startsWith("## ")) {
          const itemType = normalizedItemType(v);
          items[itemType] = {
            itemType,
            label: v.replace(/^## /, ""),
            rows: [],
            icon: "",
          };
          return { currentLabel: itemType, items };
        } else if (currentLabel) {
          if (v.startsWith("![")) {
            items[currentLabel].icon = parseIcon(v);
          } else {
            items[currentLabel].rows.push(v);
          }
        }
        return { currentLabel, items };
      },
      {
        currentLabel: null as string | null,
        items: {},
      } as MarkdownReduction
    );

    const items = Object.keys(reductionItems.items).reduce(
      (items, itemType) => {
        const reductionItem = reductionItems.items[itemType];
        items[itemType] = {
          itemType: reductionItem.itemType,
          label: reductionItem.label,
          icon: reductionItem.icon,
          markup: md.render(reductionItem.rows.join("\n")),
        };
        return items;
      },
      {} as { [key: string]: MarkdownItem }
    );

    const entitlements = markdown
      .split("\n")
      .filter((value) => value.startsWith("## "))
      .map(normalizedItemType)
      .map((v) => items[v]);

    return entitlements;
  })
);

/**
 * Hook which returns the `entitlements` loadable
 *
 * See: https://jotai.org/docs/utils/loadable
 * @returns loadable(entitlements)
 */
export function useEntitlementsLoadable() {
  const [entitlements] = useAtom(entitlementsLoadble);
  return entitlements;
}

/**
 * Hook which returns a function that can be used to refresh/reload the `entitlementsLoadable`
 * @returns refreshOrders() callback
 */
export function useRefreshEntitlements() {
  const [refresh, setRefresh] = useAtom(refreshAtom);
  /**
   * Trigger a reload/refresh for the `entitlementsLoadble`
   */
  return function refreshEntitlements() {
    setRefresh(refresh + 1);
  };
}

function normalizedItemType(v: string): string {
  return v
    .replace(/^## /, "")
    .replace(/[^A-Za-z ]/g, "")
    .replace(/ +/g, "-")
    .toLowerCase();
}

function parseIcon(iconText: string): string {
  return iconText.replace(/!\[icon\]\((.*?)\)/, "$1");
}
