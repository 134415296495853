import styled from "@emotion/styled";
import { Box } from "@mui/material";
import NextLink from "next/link";
import { useRouter } from "next/navigation";
import { translationKeys, useIntl } from "services";
import { BackIcon } from "./BackIcon";

const Link = styled(NextLink)`
  align-self: start;
  @media (max-width: 768px) {
    margin-top: 0;
  }
`;

const IconWrapper = styled.div``;

interface BackNavProps {
  href?: string;
  ariaLabel?: translationKeys;
  routeBack?: boolean;
}

export function BackNav({ href, ariaLabel, routeBack }: BackNavProps) {
  const { formatMessage } = useIntl();
  const router = useRouter();
  return (
    <Box mb={"32px"}>
      {routeBack ? (
        <IconWrapper
          title={formatMessage({ id: "imageAlt.backLink" })}
          role="button"
          onClick={() => router.back()}
        >
          <BackIcon />
        </IconWrapper>
      ) : (
        <Link
          id="navBack"
          href={href ?? "/"}
          aria-label={formatMessage({ id: ariaLabel ?? "ariaLabels.back" })}
        >
          <BackIcon title={formatMessage({ id: "imageAlt.backLink" })} />
        </Link>
      )}
    </Box>
  );
}
