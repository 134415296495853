import styled from "@emotion/styled";

interface Props {
  selected: boolean;
}

export const PanelSelect = styled.div<Props>`
  position: relative;
  box-sizing: border-box;
  padding: 16px;
  background: #fff;
  border: 1px solid;
  border-radius: 5px;
  box-shadow: 0 3px 9px rgb(0 0 0 / 10%);
  cursor: pointer;
  border-color: ${(props) => (props.selected ? "#7DB8E5" : " #e5e5e5")};
  background: ${(props) => (props.selected ? "#F2F8FC" : " #FFFFFF")};
`;
