import { OrderAddress } from "models";
import styled from "@emotion/styled";
import { FormattedMessage } from "services";

const Container = styled.div();

type AddressProps = {
  address: OrderAddress;
  extraClass?: string;
};

export function renderAddressRegion(region?: string) {
  return region ? `, ${region}` : "";
}
export function Address({ address, extraClass }: AddressProps) {
  if (
    address.street1 === null &&
    address.locality === null &&
    address.postalCode === null &&
    address.region === null
  ) {
    return (
      <Container className={extraClass}>
        <FormattedMessage id={"errors.address.missing"} />
      </Container>
    );
  }

  return (
    <Container className={extraClass} data-dd-privacy="mask">
      <div>{address.street1}</div>
      {address.street2 ? <div>{address.street2}</div> : null}
      <div>
        <span>{address.locality}</span>
        <span>{renderAddressRegion(address.region)}</span>
        <span>{` ${address.postalCode}`}</span>
      </div>
    </Container>
  );
}
