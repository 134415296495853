import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MUISelect,
  SelectProps,
} from "@mui/material";
import styled from "@emotion/styled";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { useId } from "react";

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  "& label": {
    backgroundColor: theme.palette.background.default,
    "&.MuiInputLabel-shrink": {
      top: 0,
    },
  },
  ".MuiOutlinedInput-notchedOutline": {
    borderRadius: "8px",
  },
}));

type Options = {
  title: string;
  value: string;
  description?: string;
  /**
   * Use if you want to render jsx as the item rather than text
   */
  content?: React.ReactNode;
}[];

type OurSelectProps = SelectProps & {
  options: Options;
  value?: string | number | undefined;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    child: React.ReactNode
  ) => void;
};

export function Select(props: OurSelectProps) {
  const labelId = useId();
  const label = props.label ? (
    <InputLabel id={labelId}>{props.label}</InputLabel>
  ) : null;

  const cssClassName = props.className;
  const propsWithoutStyling = { ...props, className: "" };

  return (
    <StyledFormControl
      className={cssClassName}
      fullWidth
      aria-label={`Select ${props.name}`}
    >
      {label}
      <MUISelect
        IconComponent={KeyboardArrowDown}
        {...propsWithoutStyling}
        labelId={labelId}
        size="medium"
      >
        {props.options.map((option) =>
          option.content ? (
            option.content
          ) : (
            <MenuItem key={option.value} value={option.value}>
              {option.title}
            </MenuItem>
          )
        )}
      </MUISelect>
    </StyledFormControl>
  );
}
