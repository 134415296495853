import { LoadingDisplay } from "components/common/LoadingDisplay";
import { useOrderLoadRedirect } from "hooks/useOrderLoadRedirect";
import { Order } from "models/Order";
import { createContext, PropsWithChildren, useContext } from "react";
import { useOrderLoader } from "./OrderLoaderContext";

//-----------------------------
// Order context & Order Guard
//-----------------------------

export const OrderGuardContext = createContext<Order>(
  new Order({ id: "unloaded" })
);

type Props = {
  children: React.ReactNode;
  disableRedirects?: boolean;
};

/**
 * Wrap pages in this component to show a loader while we load
 * the order, and then only render the page once loaded. Child
 * components can then use the `useOrder` hook the guarantee the order exists.
 *
 * This component also handles any redirects needed when we load the order
 * to take the user to the right place based on their order status. This
 * is achieved with the `useOrderLoadRedirect` hook.
 * @param param0
 * @returns
 */
export const OrderGuard: React.FC<Props> = ({ children, disableRedirects }) => {
  const { order, status, error } = useOrderLoader();
  const { readyToRender } = useOrderLoadRedirect(order, disableRedirects);
  switch (status) {
    case "unloadedSaved":
    case "unloadedNoSaved":
    case "loadingSaved":
    case "loadingNoSaved": {
      return <LoadingDisplay />;
    }
    case "loaded":
    case "refreshing": {
      return readyToRender ? (
        <OrderGuardContext.Provider value={order}>
          {children}
        </OrderGuardContext.Provider>
      ) : (
        <LoadingDisplay />
      );
    }
    case "orderless": {
      throw new Error(`OrderGuard Error: No orders found for customer.`);
    }
    case "error": {
      throw new Error(`OrderGuard Error: Failed to load order. ${error}`);
    }
    default: {
      const EXHAUST_CHECK: never = status;
      return null;
    }
  }
};

/**
 * Guarantees the selected order loaded from the OrderLoader.
 * Make sure this component contained by an <OrderGuard/>
 * somewhere up the hierarchy.
 * @returns The selected Order
 */
export const useOrder = () => {
  const order = useContext(OrderGuardContext);
  if (order.id === "unloaded") {
    console.error(
      "You are trying to use useOrder() without wrapping your component or page with <OrderGuard/>. If your component should be able to be renderered with or without an order, please use `useMaybeOrder()` instead."
    );
  }
  return order;
};
