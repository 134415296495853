import s from "./Gauge.module.css";

// Original Values
// const STROKE_WIDTH = 4;
// const CX = 21;
// const CY = CX;
// const RADIUS = 15.91549430918954;

const STROKE_WIDTH = 3;
const CX = 20;
const CY = CX;
const RADIUS = 3.14 * (CX / 4);

const GRAY_COLOR = "#E5E5E5";
const GREEN_COLOR = "#62CE91";
const RED_COLOR = "#f74049";
const SEGMENT_OFFSET = 10;

interface GaugeProps {
  value?: number;
  children?: React.ReactNode;
}

export function Gauge({ value = 0, children }: GaugeProps) {
  const calculatedGreenValue = (offset = 0) => {
    const greenValue = value;
    if (greenValue <= 100 && greenValue > 0) {
      return `${greenValue - offset} ${100 - greenValue + offset}`;
    }
  };
  const calculatedRedValue = (offset = 0) => {
    const redValue = value - 100;
    // console.log('RED: ', value, redValue);
    if (redValue <= 100 && value > 0) {
      return `${redValue - offset} ${100 - redValue + offset}`;
    }
  };
  return (
    <div className={s.gauge_container}>
      <svg viewBox={`0, 0, ${CX * 2}, ${CY * 2}`} className={s.gauge_svg}>
        <defs>
          <linearGradient id="linear" gradientTransform="rotate(90)">
            <stop offset="25%" stopColor={GREEN_COLOR} />
            <stop offset="45%" stopColor={RED_COLOR} />
          </linearGradient>
        </defs>
        {value < 100 && (
          <circle
            className="donut-ring"
            cx={CX}
            cy={CY}
            r={RADIUS}
            fill="transparent"
            stroke={GRAY_COLOR}
            strokeWidth={STROKE_WIDTH}
          ></circle>
        )}

        {value < 100 && value > 0 && (
          <circle
            className="donut-green-segment"
            cx={CX}
            cy={CY}
            r={RADIUS}
            fill="transparent"
            stroke={GREEN_COLOR}
            strokeWidth={STROKE_WIDTH}
            strokeDasharray={calculatedGreenValue()}
            strokeDashoffset="0"
            strokeLinecap="round"
          ></circle>
        )}

        {value < 100 && value > 0 && (
          <circle
            className="donut-ring-overlay"
            cx={CX}
            cy={CY}
            r={RADIUS}
            fill="transparent"
            stroke="#E5E5E5"
            strokeWidth={STROKE_WIDTH}
            strokeDasharray="5 95"
            strokeDashoffset="5"
          ></circle>
        )}

        {value < 100 && value > 90 && (
          <circle
            className="donut-green-segment"
            cx={CX}
            cy={CY}
            r={RADIUS}
            fill="transparent"
            stroke="#62CE91"
            strokeWidth={STROKE_WIDTH}
            strokeDasharray={calculatedGreenValue(SEGMENT_OFFSET)}
            strokeDashoffset={`-${SEGMENT_OFFSET}`}
            strokeLinecap="round"
          ></circle>
        )}

        {value >= 100 && value < 200 && (
          <circle
            className="donut-ring"
            cx={CX}
            cy={CY}
            r={RADIUS}
            fill="transparent"
            stroke={GREEN_COLOR}
            strokeWidth={STROKE_WIDTH}
          ></circle>
        )}
        {value > 100 && value < 200 && (
          <circle
            className="donut-gradient"
            cx={CX}
            cy={CY}
            r={RADIUS}
            fill="none"
            stroke="url(#linear)"
            strokeWidth={STROKE_WIDTH}
            strokeDasharray="20 80"
            strokeDashoffset="20"
            // stroke-linecap="round"
          ></circle>
        )}

        {value > 100 && value < 200 && (
          <circle
            className="donut-gradient-overlay"
            cx={CX}
            cy={CY}
            r={RADIUS}
            fill="none"
            stroke={GREEN_COLOR}
            strokeWidth={STROKE_WIDTH}
            strokeDasharray="20 80"
            strokeDashoffset="0"
          ></circle>
        )}

        {value > 100 && value < 200 && (
          <circle
            className="donut-red-segment"
            cx={CX}
            cy={CY}
            r={RADIUS}
            fill="transparent"
            stroke={RED_COLOR}
            strokeWidth={STROKE_WIDTH}
            strokeDasharray={calculatedRedValue()}
            strokeDashoffset="0"
            strokeLinecap="round"
          ></circle>
        )}

        {value >= 200 && (
          <circle
            className="donut-full-red-segment"
            cx={CX}
            cy={CY}
            r={RADIUS}
            fill="none"
            stroke={RED_COLOR}
            strokeWidth={STROKE_WIDTH}
            strokeDasharray="100 0"
            strokeDashoffset="0"
            strokeLinecap="round"
          ></circle>
        )}
      </svg>
      <div className={s.gauge_content_container}>
        <div className={s.gauge_content_wrapper}>
          <div className={s.gauge_content}>{children}</div>
        </div>
      </div>
    </div>
  );
}
