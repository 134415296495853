import { atom, useAtomValue } from "jotai";
import { Order } from "models";

/**
 * orders atom which retrieves the depot order list, processes it for use in
 * the UI and then returns final output.
 */
export const ordersAtom = atom<Order[]>([]);

/**
 * Hook/helper to easily return the current list of orders
 * @returns Orders[]
 */
export function useOrders() {
  return useAtomValue(ordersAtom);
}
