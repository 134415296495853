import { ReactNode, FC, PropsWithChildren } from "react";
import {
  Modal as MuiModal,
  Paper as MuiPaper,
  Typography,
  Stack,
  IconButton,
  Divider,
} from "@mui/material";
import styled from "@emotion/styled";
import CloseIcon from "@mui/icons-material/Close";
import { theme } from "styles";

interface ModalProps {
  children: ReactNode;
  open: boolean;
  title?: string;
  titleIcon?: React.ReactNode;
  titleSize?: "normal" | "small";
  onClose?(): void;
  width?: number;
}

const StyledModal = styled(MuiModal)`
  display: grid;
`;

const StyledPaper = styled(MuiPaper)<{ width: number }>`
  place-self: center;
  padding: 30px;
  position: relative;
  border-radius: 8px;
  color: ${theme.palette.primary.main};
  width: ${(props) => props.width}px;
  @media (max-width: 768px) {
    width: 100%;
    border-radius: 0;
  }
`;

export const Modal: React.FC<ModalProps> = ({
  open,
  onClose,
  title,
  titleIcon,
  children,
  width = 400,
  titleSize = "normal",
}) => {
  return (
    <StyledModal
      open={open}
      aria-labelledby={title}
      aria-label={title}
      style={{ overflowY: "scroll" }}
    >
      <StyledPaper data-testid="modal" width={width}>
        <Stack direction="row" gap={2} sx={{ alignItems: "center" }}>
          {title && (
            <>
              {titleIcon}
              <Typography
                variant={titleSize === "normal" ? "xlHeading" : "lHeading"}
                color="primary"
                component="h2"
              >
                {title}
              </Typography>
            </>
          )}
          {onClose && (
            <IconButton sx={{ marginLeft: "auto" }} onClick={onClose}>
              <CloseIcon color="primary" />
            </IconButton>
          )}
        </Stack>
        {title && <Divider sx={{ margin: "28px 0" }} />}
        {children}
      </StyledPaper>
    </StyledModal>
  );
};

const StyledStack = styled(Stack)`
  margin-top: 36px;
  .MuiButton-root {
    height: "64px";
  }
  @media (max-width: 768px) {
    position: sticky;
    bottom: 0;
    padding-top: 16px;
    padding-bottom: 16px;
    background-color: white;
  }
`;
export const ModalButtons: FC<PropsWithChildren> = ({ children }) => {
  return (
    <StyledStack gap={2} direction="row" alignItems="stretch">
      {children}
    </StyledStack>
  );
};
