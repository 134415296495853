import classnames from "classnames";
import scss from "./Badge.module.scss";

export type BadgeColor = "blue" | "yellow" | "green" | "grey" | "red";

const badgeClasses = {
  blue: scss.blue,
  yellow: scss.yellow,
  green: scss.green,
  grey: scss.grey,
  red: scss.red,
};

type BadgeProps = {
  color?: BadgeColor;
  className?: string;
  children?: React.ReactNode;
};

export function Badge({
  children,
  color = "blue",
  className,
  ...rest
}: BadgeProps) {
  // Extra span wraps to avoid stretching behavior while in flex container
  return (
    <span>
      <span
        {...rest}
        className={classnames(scss.badge, className, badgeClasses[color])}
      >
        {children}
      </span>
    </span>
  );
}
