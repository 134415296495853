import styled from "@emotion/styled";
import { ReactNode } from "react";

const SubText = styled.p({
  fontSize: "0.75rem",
  lineHeight: "1rem",
});

const RedStar = styled.span({
  fontSize: "13px",
  fontWeight: 600,
  color: "#f94550",
  verticalAlign: "bottom",
});

interface FootnoteProps {
  ariaLabel?: string;
  children?: ReactNode;
  showMarker?: boolean;
}

export function FootnoteMarker({ ariaLabel }: FootnoteProps) {
  return (
    <span aria-label={ariaLabel}>
      <RedStar>*</RedStar>
    </span>
  );
}

export function Footnote({
  ariaLabel,
  children,
  showMarker = true,
}: FootnoteProps) {
  return (
    <SubText aria-label={ariaLabel}>
      {showMarker && <FootnoteMarker />}
      {children}
    </SubText>
  );
}
