// Routes that don't need an order context
// because their context doesn't relate to a particular order
export const nonOrderRoutes = [
  "/activate",
  "/mymoves",
  "/settings",
  "/selfmove",
  "/login",
  "/login/callback",
  "/logout",
  "/tos",
  "/support",
];

export const counselingUncompleteAllowedRoutes = [
  ...nonOrderRoutes,
  "/entitlements",
  "/inventory",
  "/communication-history",
];
