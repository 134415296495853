import { ReactNode } from "react";
import {
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import { CssBaseline, GlobalStyles } from "@mui/material";
import { ThemeProvider as EmotionThemeProvider } from "@emotion/react";
import { theme, globalStyles } from "styles";

interface ThemeProviderProps {
  children: ReactNode;
}

/**
 * Component which provides the application theme and base styles.
 * @param props
 * @returns JSX.Element
 */
export function ThemeProvider({ children }: ThemeProviderProps) {
  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <EmotionThemeProvider theme={theme}>
          <CssBaseline />
          <GlobalStyles styles={globalStyles} />
          {children}
        </EmotionThemeProvider>
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
}
