import { ReactNode, MouseEventHandler, CSSProperties } from "react";
import Link from "next/link";
import classnames from "classnames";
import scss from "./Button.module.scss";
import { CircularProgress, Typography } from "@mui/material";

type ButtonProps = {
  to?: string;
  href?: string;
  onClick?: MouseEventHandler<HTMLElement>;
  type?: "button" | "submit" | "reset" | undefined;
  variant?: "primary" | "secondary";
  size?: "default" | "large" | "block" | "medium";
  target?: string;
  disabled?: boolean;
  download?: string;
  className?: string;
  children: ReactNode;
  isSubmitting?: boolean;
  ariaLabel?: string;
  title?: string;
  style?: CSSProperties;
};

export function Button({
  to,
  href,
  onClick,
  type,
  target,
  variant = "primary",
  size = "default",
  disabled = false,
  className,
  download,
  children,
  isSubmitting,
  ariaLabel,
  ...rest
}: ButtonProps) {
  const textVariant = size === "medium" ? "sHeading" : "mHeading";

  const content = isSubmitting ? (
    <CircularProgress size="16px" />
  ) : (
    <Typography variant={textVariant}>{children}</Typography>
  );

  return to ? (
    <Link
      href={to}
      style={{ textDecoration: "none" }}
      onClick={onClick}
      aria-label={ariaLabel}
    >
      <button
        className={classnames(scss.button, className, {
          [scss.primaryButton]: variant === "primary",
          [scss.secondaryButton]: variant === "secondary",
          [scss.largeButton]: size === "large",
          [scss.blockButton]: size === "block",
          [scss.mediumButton]: size === "medium",
        })}
        tabIndex={0}
        {...rest}
      >
        {content}
      </button>
    </Link>
  ) : href ? (
    <a
      href={href}
      target={target}
      {...rest}
      tabIndex={0}
      className={classnames(scss.button, className, {
        [scss.primaryButton]: variant === "primary",
        [scss.secondaryButton]: variant === "secondary",
        [scss.largeButton]: size === "large",
        [scss.blockButton]: size === "block",
        [scss.mediumButton]: size === "medium",
      })}
      download={download}
    >
      {content}
    </a>
  ) : (
    <button
      {...rest}
      onClick={onClick}
      type={type}
      tabIndex={0}
      className={classnames(scss.button, className, {
        [scss.primaryButton]: variant === "primary",
        [scss.secondaryButton]: variant === "secondary",
        [scss.largeButton]: size === "large",
        [scss.blockButton]: size === "block",
        [scss.mediumButton]: size === "medium",
      })}
      disabled={disabled}
      aria-label={ariaLabel}
    >
      {content}
    </button>
  );
}
