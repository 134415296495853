import { css } from "@emotion/react";
import { theme } from "./theme";

export const globalStyles = css`
  @import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap");

  * {
    font-family: ${theme.typography.fontFamily};
  }

  html,
  body,
  #__next {
    min-height: 100vh;
    color: #01314a;
  }

  main {
    flex-direction: column;
  }

  html {
    overflow-y: scroll;
    background: #fff;
  }

  a {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    color: #0069a0;
    text-decoration: underline;

    &:hover {
      color: #0083c8;
    }
  }

  h1 {
    margin: 0;
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 3.25rem;
  }

  h3,
  h4 {
    font-weight: 600;
  }

  .page-title {
    margin-bottom: 1.5rem;

    a {
      text-decoration: underline;
      color: #045d93;
    }
  }

  table {
    text-align: left;
  }

  .MuiModal-backdrop {
    background: rgba(18, 48, 72, 0.85);
  }

  /* Position the chat widget under modals
  *  https://mui.com/material-ui/customization/z-index/ 
  */
  .ie-div-position-customer-chat {
    position: relative;
    z-index: 1299;
  }

  /* Fixed position some elements in the chat widget. 
   * We need to do this because the chat widget script
   * applies these styles AFTER creating the elements, causing
   * the page to jump. 
   */
  #chat-div-wrap {
    position: fixed;
  }

  .button-iframe-wrap {
    position: fixed;
  }
`;
