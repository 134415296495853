import { MouseEventHandler, ReactNode } from "react";
import classnames from "classnames";
import scss from "./Panel.module.scss";
import phoneIcon from "./assets/phone.svg";
import couchIcon from "./assets/couch.svg";
import truckIcon from "./assets/truck.svg";
import boxesAndLampIcon from "./assets/boxes-and-lamp.svg";
import alertSuccessIcon from "./assets/alert-success.svg";
import alertDangerIcon from "./assets/alert-danger.svg";
import completedCheckmarkIcon from "./assets/completed-checkmark.svg";
import calendarCheck from "./assets/calendar-check.svg";
import { Box, Typography } from "@mui/material";

type PanelIcons = "phone" | "couch" | "truck" | "boxes_and_lamp" | "calendar";
type AlertTypes = "success" | "danger" | "info";

type PanelProps = {
  icon?: PanelIcons;
  title?: ReactNode;
  variant?: "primary" | "default";
  children: ReactNode;
  completed?: boolean;
  className?: string;
  alert?: ReactNode;
  alertType?: AlertTypes;
  ariaLabel: string;
  onClick?: MouseEventHandler;
};

export function Panel({
  icon,
  title,
  variant = "default",
  children,
  completed,
  className,
  alert,
  alertType = "info",
  ariaLabel,
  onClick,
  ...rest
}: PanelProps) {
  const isSelectable = onClick !== undefined;
  const isPrimary = variant === "primary";
  const pointerOnHover = typeof onClick !== "undefined";
  return (
    <div
      {...rest}
      aria-label={ariaLabel}
      onClick={onClick}
      className={classnames(scss.panel, className, {
        [scss.primaryPanel]: isPrimary,
        [scss.hoverPanel]: pointerOnHover,
        [scss.selectedPanel]: completed,
        [scss.selectable]: isSelectable,
      })}
      tabIndex={0}
    >
      {completed && (
        <div
          className={scss.panelCompleted}
          aria-label={ariaLabel + " completed"}
        >
          <img src={completedCheckmarkIcon.src} />
        </div>
      )}
      <div className={classnames(scss.panelBody)}>
        {icon && (
          <div className={classnames(scss.panelIcon)}>
            <PanelIcon icon={icon} />
          </div>
        )}
        <div className={classnames(scss.panelContent)}>
          {title && (
            <Box mb="8px">
              <Typography variant="lHeading">{title}</Typography>
            </Box>
          )}
          <Typography variant="sBody">{children}</Typography>
        </div>
      </div>
      {alert && (
        <div
          className={classnames(
            scss.alert,
            { [scss.alertDanger]: alertType === "danger" },
            { [scss.alertSuccess]: alertType === "success" }
          )}
        >
          <div className={classnames(scss.alertIcon)}>
            <AlertIcon icon={alertType} />
          </div>
          {alert}
        </div>
      )}
    </div>
  );
}

type PanelIconProps = {
  icon: PanelIcons;
};

const PanelIcon = ({ icon }: PanelIconProps) => {
  switch (icon) {
    case "phone":
      return <img src={phoneIcon.src} />;
    case "couch":
      return <img src={couchIcon.src} />;
    case "truck":
      return <img src={truckIcon.src} />;
    case "boxes_and_lamp":
      return <img src={boxesAndLampIcon.src} />;
    case "calendar":
      return <img src={calendarCheck.src} />;
    default:
      return null;
  }
};

type AlertIconProps = {
  icon: AlertTypes;
};

const AlertIcon = ({ icon }: AlertIconProps) => {
  switch (icon) {
    case "success":
      return <img src={alertSuccessIcon.src} />;
    case "danger":
      return <img src={alertDangerIcon.src} />;
    default:
      return null;
  }
};
