import Loader from "./Loader";
import Overlay, { OverlayProps } from "./Overlay";

import boxTop from "./assets/box-top.svg";
import boxBottom from "./assets/box-bottom.svg";
import bed from "./assets/bed.svg";
import mirror from "./assets/mirror.svg";
import lamp from "./assets/lamp.svg";
import desk from "./assets/desk.svg";
import couch from "./assets/couch.svg";

export function LoadingDisplay({
  position = "fixed",
  gridTemplateRowsMaxPx = 700,
}: OverlayProps) {
  return (
    <Overlay position={position} gridTemplateRowsMaxPx={gridTemplateRowsMaxPx}>
      <Loader>
        <img src={boxTop.src} className="top" />
        <img src={boxBottom.src} className="bottom" />
        <img src={bed.src} className="loader__item loader__bed" />
        <img src={mirror.src} className="loader__item loader__mirror" />
        <img src={lamp.src} className="loader__item loader__lamp" />
        <img src={desk.src} className="loader__item loader__desk" />
        <img src={couch.src} className="loader__item loader__couch" />
      </Loader>
    </Overlay>
  );
}
