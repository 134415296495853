import { TextFieldProps, TextField } from "@mui/material";

type Props = TextFieldProps;

/**
 * Currently just a wrapper for MUI's input so we can tweak later
 * @param props
 * @returns
 */
export const Input: React.FC<Props> = (props) => {
  return (
    <TextField
      {...props}
      value={props.value || ""}
      fullWidth
      variant="outlined"
      size="medium"
      sx={{
        ".MuiOutlinedInput-notchedOutline": {
          borderRadius: " 8px",
        },
      }}
    />
  );
};
