import classnames from "classnames";
import { useIntl } from "services";
import scss from "./Counter.module.scss";
import decrementIcon from "./assets/minus.svg";
import decrementDisabledIcon from "./assets/minus-disabled.svg";
import incrementIcon from "./assets/plus.svg";
import incrementDisabledIcon from "./assets/plus-disabled.svg";

interface CounterProps {
  value: number;
  decrement: () => void;
  decrementDisabled: boolean;
  increment: () => void;
  incrementDisabled: boolean;
}

export function Counter({
  value,
  decrement,
  decrementDisabled,
  increment,
  incrementDisabled,
}: CounterProps) {
  const { formatMessage } = useIntl();

  const handleDecrement = () => {
    if (!decrementDisabled) {
      decrement();
    }
  };

  const handleIncrement = () => {
    if (!incrementDisabled) {
      increment();
    }
  };

  return (
    <div className={classnames(scss.counter)}>
      <button
        tabIndex={-1}
        aria-label={formatMessage({
          id: "ariaLabels.decrementQuantity",
        })}
        className={classnames(scss.button)}
        disabled={decrementDisabled}
        onClick={handleDecrement}
      >
        {decrementDisabled ? (
          <img src={decrementDisabledIcon.src} />
        ) : (
          <img src={decrementIcon.src} />
        )}
      </button>
      <div
        aria-label={formatMessage({
          id: "ariaLabels.quantity",
        })}
        className={classnames(scss.valueWrapper)}
      >
        <div className={classnames(scss.value)}>{value}</div>
      </div>
      <button
        tabIndex={-1}
        aria-label={formatMessage({
          id: "ariaLabels.incrementQuantity",
        })}
        className={classnames(scss.button)}
        disabled={incrementDisabled}
        onClick={handleIncrement}
      >
        {incrementDisabled ? (
          <img src={incrementDisabledIcon.src} />
        ) : (
          <img src={incrementIcon.src} />
        )}
      </button>
    </div>
  );
}
