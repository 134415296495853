import {
  DatePicker as MuiDatePicker,
  DatePickerProps,
} from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterTemporalPlainDate } from "@movehq/mui-datetime";
import { Temporal } from "@movehq/datetime";
import { Input } from "..";
import { translationKeys, useIntl } from "services";
import { ErrorBoundary } from "react-error-boundary";
import { Alert } from "@mui/material";

interface Props extends DatePickerProps<Temporal.PlainDate> {
  ariaLabelKey?: translationKeys;
  ariaLabel?: string;
  name?: string;
}

interface JSDateProps extends DatePickerProps<any> {
  ariaLabelKey?: translationKeys;
  ariaLabel?: string;
  name?: string;
}

export const DatePicker: React.FC<JSDateProps> = (props) => {
  const { formatMessage } = useIntl();

  const ariaLabel =
    props.ariaLabelKey !== undefined
      ? formatMessage({ id: props.ariaLabelKey })
      : props.ariaLabel
      ? props.ariaLabel
      : undefined;

  return (
    <ErrorBoundary
      fallback={<Alert severity="error">DatePicker failed to load</Alert>}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MuiDatePicker
          slotProps={{
            textField: {
              variant: "outlined",
              "aria-label": ariaLabel,
              name: props.name,
            },
          }}
          slots={{ textField: Input }}
          {...props}
        />
      </LocalizationProvider>
    </ErrorBoundary>
  );
};

export const TemporalDatePicker: React.FC<Props> = (props) => {
  const { formatMessage } = useIntl();

  const ariaLabel =
    props.ariaLabelKey !== undefined
      ? formatMessage({ id: props.ariaLabelKey })
      : props.ariaLabel
      ? props.ariaLabel
      : undefined;

  return (
    <ErrorBoundary
      fallback={<Alert severity="error">DatePicker failed to load</Alert>}
    >
      <LocalizationProvider dateAdapter={AdapterTemporalPlainDate}>
        <MuiDatePicker
          slotProps={{
            textField: {
              variant: "outlined",
              "aria-label": ariaLabel,
              name: props.name,
            },
          }}
          slots={{ textField: Input }}
          {...props}
        />
      </LocalizationProvider>
    </ErrorBoundary>
  );
};
