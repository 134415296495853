import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";

const popin = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export type OverlayProps = {
  position?: "fixed" | "flex";
  gridTemplateRowsMaxPx?: number;
};

export default styled.div<OverlayProps>`
  position: ${(props) => props.position};
  width: 100%;
  height: 100%;
  left: 0;
  place-items: start;
  align-items: center;
  animation: ${popin} 0.4s ease 0s;
  display: grid;
  grid-template-rows: minmax(100px, ${(props) => props.gridTemplateRowsMaxPx}px) 1fr;
`;
